<template>
  <div class="col-md-9 mx-auto mt-2">
    <base-card cardClass="card card-info">
      <div class="card-header">
        <h3 class="card-title">Edit Sub Resource</h3>
      </div>
      <form class="form-horizontal" @submit.prevent="editSubResourceHandler()">
        <div class="card-body">
          <div class="form-group row">
            <label for="resource name" class="col-sm-3 col-form-label"
              >Sub Resource Name<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="subResourceName"
              />
              <ErrorMessage v-if="subResourceNameError">{{
                subResourceNameError
              }}</ErrorMessage>
            </div>
          </div>
          <div class="form-group row">
            <label for="resource name" class="col-sm-3 col-form-label"
              >Resource Name<AsteriskSymbol
            /></label>
            <div class="col-sm-9">
              <select
                style="width: 100%"
                v-model="resourceName"
                class="form-control"
              >
                <option value="">Select</option>
                <option
                  v-for="resource in allResourceName"
                  :value="resource.resource_id"
                  :key="resource.resource_id"
                >
                  {{ resource.resource_name + " in " + resource.building_name }}
                </option>
              </select>
              <ErrorMessage v-if="resourceNameError"
                >Please select a valid resource name</ErrorMessage
              >
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-info">Edit Sub Resource</button>
        </div>
      </form>
    </base-card>
  </div>
</template>
<script>
import ErrorMessage from "../UI/ErrorMessage.vue";
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
import axios from "axios";
export default {
  data() {
    return {
      subResourceName: "",
      resourceName: "",
      subResourceNameError: null,
      resourceNameError: null,
      allResourceName: {},
      // fetchedSubResource:{}
    };
  },
  components: {
    ErrorMessage,
    AsteriskSymbol,
  },
  props: ["id"],
  methods: {
    editSubResourceHandler() {
      axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.SUB_RESOURCE_API +
            "/" +
            this.id,
          {
            sub_resource_name: this.subResourceName,
            resource_ref_id: this.resourceName,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.$router.push('/viewAllSubResource')
          } else {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
          if (err.response.status === 422) {
            if (err.response.data.errors.sub_resource_name) {
              this.subResourceNameError =
                err.response.data.errors.sub_resource_name[0];
            } else {
              this.subResourceNameError = null;
            }
            if (err.response.data.errors.resource_ref_id) {
              this.resourceNameError =
                err.response.data.errors.resource_ref_id[0];
            } else {
              this.resourceNameError = null;
            }
          }
        });
    },
    getAllResourceName() {
      axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_DISTRIBUTED_RESOURCE,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.allResourceName = res.data.data;
          } else {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    fetchSubResource() {
      axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.SUB_RESOURCE_API +
            "/" +
            this.id,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.subResourceName = res.data.data.sub_resource_name;
            this.resourceName = res.data.data.resource_ref_id;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.getAllResourceName();
    this.fetchSubResource();
  },
};
</script>
