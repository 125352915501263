<template>
  <TableView>
    <template #card-header>
      <h3 class="card-title">View All Staff</h3>
      <div class="row">
        <search-input
          class="ml-auto"
          placeholder="Fname, Mobile, Unique Id"
          v-model="searchParam"
          @input="searchCaretaker()"
          @change-input="changeSearchVal"
        ></search-input>
        <div class="col-2">
          <router-link
            to="/addNewStaff"
            class="btn btn-block bg-gradient-success"
          >
            Add New
          </router-link>
        </div>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Staff Name</th>
      <th>Phone</th>
      <th>Role</th>
      <th>Is Active</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr v-for="(staff, index) in allStaff" :key="staff.staff_id">
        <td>{{ index + 1 }}</td>
        <td>
          {{ getStaffName(staff) }}
        </td>

        <td>{{ staff.staff_mobile }}</td>
        <td>{{ staff.role }}</td>
        <td
          v-if="staff.is_active"
          class="text-success fake-link"
          style="cursor: pointer"
          @click="
            changeStatusOfStaff(
              staff.staff_id,
              staff.staff_firstname + ' ' + staff.staff_lastname,
              staff.is_active
            )
          "
          title="Change the Status to Inactive"
        >
          Active
        </td>
        <td
          v-else
          class="text-danger fake-link"
          style="cursor: pointer"
          title="Change the Status to Active"
          @click="
            changeStatusOfStaff(
              staff.staff_id,
              staff.staff_firstname + ' ' + staff.staff_lastname,
              staff.is_active
            )
          "
        >
          In Active
        </td>
        <td>
          <span @click="editHandler(staff.staff_id)" class="show-pointer">
            <i
              class="fas fa-edit p-2"
              style="color: rgb(255, 153, 0); font-size: 22px"
            ></i>
          </span>
          <span @click="deleteHandler(staff.staff_id)" class="show-pointer"
            ><i
              class="fas fa-trash p-2"
              style="color: red; font-size: 22px"
            ></i>
          </span>
        </td>
      </tr>
    </template>
  </TableView>
  <custom-pagination :prev-page="prevPage" :next-page="nextPage " @on-update="paginationDataUpdate"/>
</template>
<style scoped>
.fake-link:hover {
  text-decoration: underline;
}
</style>
<script>
import TableView from "../UI/TableView.vue";
import Swal from "sweetalert2";
import SearchInput from "../UI/SearchInput.vue";
import debounce from "lodash.debounce";
import axios from "axios";
import CustomPagination from "../shared/pagination/CustomPagination.vue";
export default {
  data() {
    return {
      currentPage:1,
      pageSize:5,
      prevPage:false,
      nextPage:false,
      allStaff: {},
      searchParam: null,
    };
  },
  methods: {
    changeSearchVal(input) {
      this.searchParam = input;
    },
    paginationDataUpdate(currentPage, pageSize){
      this.currentPage = currentPage ;
      this.pageSize = pageSize;
      this.searchCaretaker();
    },
    changeStatusOfStaff(id, name, status) {
      Swal.fire({
        title: "Are you sure?",
        html:
          "Are you sure to change the status of <span class='text-danger'>" +
          name +
          "</span>",
        icon: "warning",
        allowEnterKey: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .patch(
              this.$Constants.BaseUrl +
                this.$Constants.ApiEndpoints.CHANGE_STAFF_STATUS +
                "/" +
                id,
              {
                change_status: !status,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.$token}`,
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                // this.$Methods.toastSuccess(res.data.message);
                this.fetchAllStaffData();
              }
            })
            .catch((err) => {
              this.$Methods.toastError(err.response.data.message);
            });
          Swal.fire(
            "Changed Successfully!",
            "The status has been shanged successfully.",
            "success"
          );
        }
      });
    },
    fetchAllStaffData() {
      axios
        .get(this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.STAFF_API, {
          headers: {
            Authorization: `Bearer ${this.$token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.allStaff = res.data.data;
          } else if (res.status === 204) {
            this.$toastError(res.data.message);
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    searchCaretaker: debounce(function () {
      // if (this.searchParam !== "") {
        let searchBody = {
          page:this.currentPage,
          size:this.pageSize,
          searchData:{
            search_str:this.searchParam,
          }
        };
        this.axios
          .post(
            this.$Constants.BaseUrl +
              this.$Constants.ApiEndpoints.SEARCH_STAFF,
              searchBody
          )
          .then((res) => {
            if (res.status === 200) {
              // console.log("sdf");
              this.allStaff = res.data.data;
            } else if (res.status === 204) {
              this.allStaff = {};
            }
          })
          .catch((err) => {
            console.log(err.response.data.message);
          });
      // } else {
      //   this.fetchAllStaffData();
      // }
    }, 300),
    editHandler(id) {
      this.$router.push(`/editStaff/${id}`);
    },
    deleteHandler(id) {
      axios
        .delete(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.STAFF_API +
            "/" +
            id,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.fetchAllStaffData();
          }
        })
        .catch((err) => {
          if (err.response.status === 409) {
            this.$Methods.errorAlert(
              "This data is connected to some other data please remove that and try again"
            );
          } else {
            this.$Methods.toastError(err.response.data.message);
          }
        });
    },
    getStaffName(staff) {
      if (staff.staff_middlename) {
        return (
          staff.staff_firstname +
          " " +
          staff.staff_middlename +
          " " +
          staff.staff_lastname
        );
      } else {
        return staff.staff_firstname + " " + "---" + " " + staff.staff_lastname;
      }
    },
  },
  components: {
    TableView,
    SearchInput,
    CustomPagination,
  },
  mounted() {
    // this.fetchAllStaffData();
    this.searchCaretaker();
  },
};
</script>
