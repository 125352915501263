<template>
  <card-layout>
    <div class="row">
      <h6 style="width: auto;">De-Select Buildings</h6>
      <base-button
        class="col-md-1 ml-auto m-2"
        classType="warning-g"
        :disabled="selectedAc?.length > 0 ? false : true"
        @click="submitHandler()"
        >UnAssign
      </base-button>
    </div>
  </card-layout>
</template>
<script>
export default {
  props: ["staffData"],
};
</script>
