<template>
  <div class="col-md-9 mx-auto mt-2">
    <base-card cardClass="card card-info">
      <template #card-header>
        <h3 class="card-title">Edit Staff</h3>
      </template>
      <template #default>
        <active-inactive
          :status="staffStatus"
          @success-status-change="fetchStaffData()"
          v-if="dataFetched"
        ></active-inactive>

        <form class="form-horizontal" @submit.prevent="submitHandler()">
          <div class="card-body">
            <div class="form-group row">
              <label for="first-name" class="col-sm-2 col-form-label"
                >First Name<AsteriskSymbol
              /></label>
              <div class="col-sm-10">
                <input
                  type="text"
                  v-model="firstName"
                  :class="firstNameClass"
                  placeholder="Piyush"
                />
                <ErrorMessage v-if="firstNameError">
                  <p v-for="err in firstNameError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>
            <div class="form-group row">
              <label for="first-name" class="col-sm-2 col-form-label"
                >Middle Name</label
              >
              <div class="col-sm-10">
                <input
                  type="text"
                  v-model="middleName"
                  :class="middleNameClass"
                  placeholder="Pramod"
                />
                <ErrorMessage v-if="middleNameError">
                  <p v-for="err in middleNameError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>

            <div class="form-group row">
              <label for="first-name" class="col-sm-2 col-form-label"
                >Last Name<AsteriskSymbol
              /></label>
              <div class="col-sm-10">
                <input
                  type="text"
                  v-model="lastName"
                  :class="lastNameClass"
                  placeholder="Jain"
                />
                <ErrorMessage v-if="lastNameError">
                  <p v-for="err in lastNameError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>
            <div class="form-group row">
              <label for="staff-unique-number" class="col-sm-3 col-form-label"
                >Staff Unique Number<AsteriskSymbol
              /></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  v-model="staffUniqueNumber"
                  :class="staffUniqueNumberClass"
                  placeholder="89745854"
                />
                <ErrorMessage v-if="staffUniqueNumberError">
                  <p v-for="err in staffUniqueNumberError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>
            <div class="form-group row">
              <label for="staff-unique-number" class="col-sm-3 col-form-label"
                >Staff Role<AsteriskSymbol
              /></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  v-model="staffRole"
                  :class="staffUniqueNumberClass"
                  placeholder="89745854"
                  readonly="true"
                />
                <ErrorMessage v-if="staffUniqueNumberError">
                  <p v-for="err in staffUniqueNumberError" :key="err">{{ err }}</p>
                </ErrorMessage>
              </div>
            </div>

            <div class="form-group row">
              <label for="first-name" class="col-sm-2 col-form-label"
                >Email<AsteriskSymbol
              /></label>
              <div class="col-sm-10">
                <input
                  type="email"
                  v-model="email"
                  :class="emailClass"
                  placeholder="piyush@gmail.com"
                />
                <ErrorMessage v-if="emailError"
                  ><p v-for="err in emailError" :key="err">
                    {{ err }}
                  </p></ErrorMessage
                >
              </div>
            </div>

            <div class="form-group row">
              <label for="first-name" class="col-sm-2 col-form-label"
                >Phone No<AsteriskSymbol
              /></label>
              <div class="col-sm-10">
                <input
                  type="phone"
                  v-model="phone"
                  :class="phoneClass"
                  placeholder="9999999999"
                />
                <ErrorMessage v-if="phoneError">
                  <p v-for="err in phoneError" :key="err" class="no-design">
                    {{ err }}
                  </p>
                </ErrorMessage>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label"
                >Department<AsteriskSymbol
              /></label>
              <div class="col-md">
                <div class="form-group">
                  <select
                    :class="deptNameClass"
                    style="width: 100%"
                    placeholder="Select Department"
                    v-model="deptName"
                  >
                    <option value="">Select</option>
                    <option v-for="dept in allDepartments" :key="dept.dept_id" :value="dept.dept_id">
                      {{ dept.department_name + " in "+ dept.building_name }}
                    </option>
                    
                  </select>
                  <ErrorMessage v-if="deptNameError">
                  <p v-for="err in deptNameError" :key="err" class="no-design">
                    {{ err }}
                  </p>
                </ErrorMessage>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="updatePassword" class="col-sm-5 col-form-label"
                >Want to Update password and send mail to staff? </label>
              <div class="col-sm mt-2 ml-4">
                <input
                  type="checkbox"
                  v-model="updatePassword"
                  class="form-check-input"
                  id="updatePassword"
                />
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-info">Update Staff</button>
          </div>
        </form>
      </template>
    </base-card>
  </div>
</template>
<style scoped>
p {
  margin-bottom: 0rem;
}
</style>
<script>
import ErrorMessage from "../UI/ErrorMessage.vue";
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
import axios from "axios";
import ActiveInactive from "./ActiveInactive.vue";
export default {
  props: ["id"],
  provide() {
    return {
      staff_id: this.id,
    };
  },
  data() {
    return {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      staffUniqueNumber:"",
      staffStatus: 0,
      dataFetched: false,
      staffRole:"",
      updatePassword:false,

      deptName:"",
      allDepartments:{},

      deptNameError:null,

      firstNameError: null,
      middleNameError: null,
      lastNameError: null,
      emailError: null,
      phoneError: null,
      staffUniqueNumberError:null,
    };
  },
  methods: {
    fetchDepartments(){
      axios.get(this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.DEPARTMENT_API,{
        headers:{
          Authorization:`Bearer ${this.$token}`
        }
      }).then(res=>{
        if(res.status === 200){
          this.allDepartments = res.data.data;
        }
      }).catch(err=>{
        this.$Methods.toastError(err.response.data.message)
      });
    },
    fetchStaffData() {
      this.dataFetched = false;
      axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.STAFF_API +
            "/" +
            this.id,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.firstName = res.data.data.staff_firstname;
            if (res.data.data.staff_middlename) {
              this.middleName = res.data.data.staff_middlename;
            }
            this.lastName = res.data.data.staff_lastname;
            this.email = res.data.data.staff_email;
            this.phone = res.data.data.staff_mobile;
            this.staffStatus = res.data.data.is_active;
            this.deptName = res.data.data.department_ref_id;
            this.staffUniqueNumber = res.data.data.staff_unique_id;
            this.dataFetched = true;
            this.staffRole = res.data.data.role;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },

    submitHandler() {
      axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.STAFF_API +
            "/" +
            this.id,
          {
            first_name: this.firstName,
            middle_name: this.middleName,
            last_name: this.lastName,
            email: this.email,
            phone_number: this.phone,
            department_name:this.deptName,
            staff_unique_number:this.staffUniqueNumber,
            update_password:this.updatePassword
          },
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.$router.push("/viewAllstaff");
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
          if (err.response.status === 422) {
            if (err.response.data.errors.first_name) {
              this.firstNameError = err.response.data.errors.first_name;
            } else {
              this.firstNameError = null;
            }
            if (err.response.data.errors.middle_name) {
              this.middleNameError = err.response.data.errors.middle_name;
            } else {
              this.middleNameError = null;
            }
            if (err.response.data.errors.last_name) {
              this.lastNameError = err.response.data.errors.last_name;
            } else {
              this.lastNameError = null;
            }
            if (err.response.data.errors.email) {
              this.emailError = err.response.data.errors.email;
            } else {
              this.emailError = null;
            }
            if (err.response.data.errors.phone_number) {
              this.phoneError = err.response.data.errors.phone_number;
            } else {
              this.phoneError = null;
            }
            if (err.response.data.errors.department_name) {
              this.deptNameError = err.response.data.errors.department_name;
            } else {
              this.deptNameError = null;
            }
            if (err.response.data.errors.staff_unique_number) {
              this.staffUniqueNumberError = err.response.data.errors.staff_unique_number;
            } else {
              this.staffUniqueNumberError = null;
            }
          }
        });
    },
  },
  computed: {
    firstNameClass() {
      return this.firstNameError ? "form-control is-invalid" : "form-control";
    },
    middleNameClass() {
      return this.middleNameError ? "form-control is-invalid" : "form-control";
    },
    lastNameClass() {
      return this.lastNameError ? "form-control is-invalid" : "form-control";
    },
    emailClass() {
      return this.emailError ? "form-control is-invalid" : "form-control";
    },
    phoneClass() {
      return this.phoneError ? "form-control is-invalid" : "form-control";
    },
    deptNameClass() {
      return this.deptNameError ? "form-control is-invalid" : "form-control";
    },
    staffUniqueNumberClass(){
      return this.staffUniqueNumberError ? "form-control is-invalid" : "form-control";
    }
  },
  components: {
    ErrorMessage,
    AsteriskSymbol,
    ActiveInactive,
  },
  mounted() {
    this.fetchDepartments();
    this.fetchStaffData();
  },
};
</script>
