export default {
  BaseUrl: "https://devserver.thecarebuzz.com/",
  // BaseUrl: "http://127.0.0.1:8000/",
  ApiEndpoints: {
    TEST: "api/test",
    LOGIN: "api/login",
    GET_TOKEN_STATUS: "api/user",
    LOGOUT: "api/logout",
    CHECK_PASSWORD: "api/checkPassword",
    CHANGE_PASSWORD: "api/changePassword",
    
    GET_ALL_COMPANY_NAME: "api/acCompanies",

    AC_COMPANY: "api/acCompany",
    RESOURCE_CATEGORY: "api/category",

    DEPARTMENT_API: "api/department",
    GET_DEPT_BUILDING_WISE:"api/getDeptBuildingWise",

    GET_ALL_BUILDINGS: "api/getAllBuildings",
    ADD_NEW_BUILDING: "api/addNewBuilding",
    DELETE_BUILDING: "api/deleteBuilding",
    GET_BUILDING: "api/getBuildingData",
    EDIT_BUILDING: "api/updateBuilding",
    GET_UNASSIGNED_BUILDINGS: "api/getUnassignedBuilding",
    ASSIGN_BUILDING_TO_STAFF: "api/assignBuilding",

    RESOURCE_API:"api/resource",
    GET_DISTRIBUTED_RESOURCE:"api/getDistributedResource",
    GET_RESOURCE_BUILDING_WISE:"api/getResourcesBuildingWise",
    
    SUB_RESOURCE_API:"api/sub_resource",
    GET_SUB_RESOURCE_OF_RESOURCE: "api/getSubResourceOfResource",

    AIR_CONDITIONER_API:"api/air_conditioner",
    SEARCH_AC:"api/searchAC",

    STAFF_API:"api/staff",
    CHANGE_STAFF_STATUS:"api/changeStaffStatus",
    GET_STAFF_DEPT_WISE:"api/getCaretakerOrStaffDeptWise",
    SEARCH_STAFF:"api/searchStaff",
    GET_ASSIGNED_AC_COUNT_STAFF:"api/fetchStaffAssignedACCount",
    GET_ASSIGNED_AC_DATA_LIST:"api/getStaffAssignedACDetails",

    GET_UNASSIGNED_AC:"api/getUnAssignedAc",
    ASSIGN_AC:"api/assginACToStaff",
    FETCH_ASSIGNED_AC:"api/fetchAssignedAC",
    UNASSIGN_AC:"api/unAssginACToStaff",

    FETCH_CLEANING_DATA:"api/fetchCleaningData",
    FETCH_NOT_CLEANED_DATA:"api/fetchNotCompletedWork",
    FETCH_STAFF_CLEAN_HISTORY:"api/fetchStaffCleanHistroy",
    FETCH_CLEANING_IMAGE:"api/fetchCleanImage",
    FETCH_SINGLE_CLEANING_DATA:"api/fetchSingleCleanDetail",
    UPDATE_CLEANING_STATUS:"api/updateCleaningsStatus",

    FETCH_DASHBOARD_DATA:"api/fetchDashboardDetails",

    ADD_NEW_ADMIN:"api/addNewAdmin",
    ACTIVATE_ACCOUNT:"api/activateAccount",
    FORGOT_PASSWORD:"api/forgotPassword",
    EDIT_FORGOT_PASSWORD:"api/editForgotPassword",
  },
};
