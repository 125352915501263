<template>
  <table-view :isLoading="isLoading">
    <template #card-header>
      <h3 class="card-title">All Sub Resources</h3>
      <div class="col-2 ml-auto">
        <router-link
          to="/addNewSubResource"
          class="btn btn-block bg-gradient-success"
        >
          Add New
        </router-link>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Sub Resource Name</th>
      <th>Resource</th>
      <th>Building</th>
      <th>Created By</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr
        v-for="(subResource, index) in allSubResource"
        :key="subResource.sub_resource_id"
      >
        <td>{{ index + 1 }}</td>
        <td>{{ subResource.sub_resource_name }}</td>
        <td>{{ subResource.resource_name }}</td>
        <td>{{ subResource.building_name }}</td>
        <td>{{ subResource.name }}</td>
        <td>
          <span @click="editHandler(subResource.sub_resource_id)" class="show-pointer">
            <i class="fas fa-edit p-2" style="color: rgb(255, 153, 0); font-size: 22px;"></i>
          </span>
          <span
            @click="deleteHandler(subResource.sub_resource_id)"
            class="show-pointer"
            ><i class="fas fa-trash p-2" style="color: red ; font-size: 22px;"></i>
          </span>
          
        </td>
      </tr>
    </template>
  </table-view>
</template>
<script>
import TableView from "../UI/TableView.vue";
import axios from "axios";
export default {
  data() {
    return {
      isLoading:false,
      allSubResource: {},
    };
  },
  components: {
    TableView,
  },
  methods: {
    getAllSubResources() {
      this.isLoading = true;
      axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.SUB_RESOURCE_API,
        )
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.allSubResource = res.data.data;
          } else {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.$Methods.toastError(err.response.data.message);
        });
    },
    deleteHandler(id) {
      axios
        .delete(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.SUB_RESOURCE_API +
            "/" +
            id,
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.getAllSubResources();
          } else {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((err) => {
        if(err.response.status === 409){
          this.$Methods.errorAlert("This data is Connected to some other data please remove that and try again!!")
        }
        else{
          this.$Methods.toastError(err.response.data.message);
        }
      
        });
    },
    editHandler(id) {
      this.$router.push(`/editSubResource/${id}`);
    },
  },
  mounted() {
    this.getAllSubResources();
  },
};
</script>
