<template>
  <div class="col-8 m-auto p-5">
    <div class="card card-primary">
      <div class="card-header">
        <h3 class="card-title">Edit Building</h3>
      </div>
      <!-- /.card-header -->
      <!-- form start -->
      <form @submit.prevent="editBuildingHandler()">
        <div class="card-body">
          <div class="form-group">
            <label for="buildingName">Building Name</label>
            <input
              type="text"
              :class="buildingNameClass"
              id="buildinfName"
              placeholder="Enter Building Name"
              v-model="buildingName"
            />
            <span class="error invalid-feedback" v-if="!isBuildingNameValid"
              >Please Enter Valid Name</span
            >
          </div>
          <div class="form-group">
            <label for="alias">Building Name</label>
            <input
              type="text"
              :class="aliasNameClass"
              id="alias"
              placeholder="MCA"
              v-model="alias"
              readonly="true"
            />
            <span class="error invalid-feedback" v-if="!isAliasNameValid"
              >Please Enter Valid Alais</span
            >
          </div>
        </div>
        <!-- /.card-body -->

        <div class="card-footer">
          <button type="submit" class="btn btn-outline-primary">Submit</button>
          <button type="reset" class="btn btn-outline-warning ml-2">
            Reset
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      buildingName: "",
      isBuildingNameValid: true,
      alias: "",
      isAliasNameValid: true,
    };
  },
  methods: {
    async editBuildingHandler() {
      const token = localStorage.getItem("apiToken");

      axios
        .post(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.EDIT_BUILDING,
          {
            building_name: this.buildingName,
            building_id: this.id,
            alias: this.alias,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.$router.push("/viewAllBuildings");
          } else {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.errors.building_name) {
            this.isBuildingNameValid = false;
          } else {
            this.isBuildingNameValid = true;
          }
          if (error.response.data.errors.alias) {
            this.isAliasNameValid = false;
          } else {
            this.isAliasNameValid = true;
          }
        });
    },
  },
  computed: {
    buildingNameClass() {
      return !this.isBuildingNameValid
        ? "form-control is-invalid"
        : "form-control";
    },
    aliasNameClass() {
      return !this.isAliasNameValid
        ? "form-control is-invalid"
        : "form-control";
    },
  },
  props: ["id"],
  mounted() {
    // console.log(this.id)
    const token = localStorage.getItem("apiToken");

    axios
      .get(
        this.$Constants.BaseUrl +
          this.$Constants.ApiEndpoints.GET_BUILDING +
          "/" +
          this.id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          this.buildingName = res.data.data.building_name;
          this.alias = res.data.data.alias;
        }
      });
  },
};
</script>
