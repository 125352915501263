<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4 ps-fixed">
    <!-- Brand Logo -->
    <div class="brand-link">
      <span class="brand-text font-weight-light ml-3">ACS Dashboard</span>
    </div>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <i
            class="fas fa-user img-circle elevation-1 p-2"
            style="color: white"
          ></i>
        </div>
        <div class="info">
          <a href="#" class="d-block">{{ userName }}</a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2 y-scroll">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item">
            <router-link to="/home" href="pages/widgets.html" class="nav-link">
              <i
                class="nav-icon fas fa-tachometer-alt"
                style="color: #ff851b"
              ></i>
              <p>
                Dashboard
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/viewAllBuildings" class="nav-link">
              <i class="nav-icon fas fa-building" style="color: red"></i>
              <p>Manage Building</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/showAllDept" class="nav-link">
              <i
                class="nav-icon fas fa-window-restore"
                style="color: rgb(0, 251, 255)"
              ></i>
              <p>Manage Department</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/viewAllCategories" class="nav-link">
              <!-- <img :src="categoryIcon" alt="" class="nav-icon" height="20"> -->
              <AllIcons iconName="Category" color="#FF00F7"></AllIcons>
              <p>Manage Category</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/getAllResources" class="nav-link">
              <i
                class="nav-icon fas fa-object-group"
                style="color: rgb(94, 204, 54)"
              ></i>
              <p>Manage Resource</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/viewAllSubResource" class="nav-link">
              <i
                class="nav-icon fas fa-puzzle-piece"
                style="color: rgb(25, 235, 165)"
              ></i>
              <p>Manage Sub Resource</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/viewAllAirConditioner" class="nav-link">
              <AllIcons iconName="AC" color="#2A8FE4"></AllIcons>
              <p>Manage AC</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/viewAllStaff" class="nav-link">
              <i
                class="nav-icon fas fa-users"
                style="color: rgb(231, 255, 19)"
              ></i>
              <p>Manage Staff</p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/assignUnAssignAC" class="nav-link">
              <i
                class="nav-icon fas fa-chain"
                style="color: rgb(136, 0, 255)"
              ></i>
              <p>Assign / UnAssign AC</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/assignUnAssignACSupervisor" class="nav-link">
              <i
                class="nav-icon fa-solid fa-user-tie"
                style="color: rgb(206, 0, 255)"
              ></i>
              <p>Supervisor Assign</p>
            </router-link>
          </li>
          <li class="nav-item" :class="{ 'menu-open': showReortSubMenu }">
            <a href="#" class="nav-link" @click="subMenuVisibiltyHandler">
              <i
                class="nav-icon fas fa-chart-pie"
                style="color: rgb(0, 110, 255)"
              ></i>
              <p>
                Reports
                <i
                  class="fas"
                  :class="showReortSubMenu ? 'fa-angle-down' : 'fa-angle-left'"
                ></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/dateWiseReport" class="nav-link">
                  <i class="fas fa-calendar ml-3"></i>
                  <p class="ml-2">Date Wise</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/caretakerWiseReport" class="nav-link">
                  <i class="fas fa-user-md ml-3"></i>
                  <p class="ml-2">Caretaker Wise</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item mb-5">
            <router-link to="/assignUnAssignACSupervisor" class="nav-link">
              <i class="nav-icon fa-solid fa-power-off" style="color: red"></i>
              <p>Logout</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- </div> -->
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import AllIcons from "../shared/AllIcons.vue";
export default {
  data() {
    return {
      userName: "",
      showReortSubMenu: false,
    };
  },
  components: {
    AllIcons,
  },
  computed: {
    showArrowForReport() {
      if (this.showReortSubMenu) {
        return "display:none";
      } else {
        return "display:block";
      }
    },
  },
  methods: {
    subMenuVisibiltyHandler() {
      this.showReortSubMenu = !this.showReortSubMenu;
    },
  },
  async mounted() {
    const res = await this.$Methods.getUserData();
    this.userName = res?.data?.name;
  },
};
</script>

<style scoped>
.ps-fixed {
  position: fixed !important;
}
.y-scroll {
  /* height: 100vh; */
  max-height: 80vh;
  overflow-y: scroll;
  max-width: auto;
  scrollbar-width: none;
  /* position: fixed !important; */
}
</style>
