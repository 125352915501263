<template>
  <card-layout>
    <div class="row">
      <h6 style="width: auto">Select Buildings</h6>
      <base-button
        class="col-md-1 ml-auto m-2"
        classType="warning-g"
        :disabled="selectedBuilding?.length > 0 ? false : true"
        @click="submitHandler()"
        >Assign
      </base-button>
    </div>
    <div class="row">
      <card-layout
        v-for="building in unAssignedBuildings"
        :key="building.building_id"
        class="w-auto m-2 show-pointer"
        :class="
          selectedBuilding.find((build) => build === building.building_id)
            ? 'bg-success'
            : ''
        "
        @click.left="selectBuildingForAssign(building)"
      >
        <building-detail :building="building"></building-detail>
      </card-layout>
    </div>
  </card-layout>
</template>
<script>
import BuildingDetail from "../buildings/BuildingDetail.vue";
export default {
  props: ["staffData"],
  components: { BuildingDetail },
  data() {
    return {
      unAssignedBuildings: [],
      selectedBuilding: [],
    };
  },
  methods: {
    fetchUnAssignedBuildings() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_UNASSIGNED_BUILDINGS
        )
        .then((res) => {
          if (res.status == 200) {
            this.unAssignedBuildings = res.data.data;
          }
        });
    },
    selectBuildingForAssign(building) {
      if (
        this.selectedBuilding.find(
          (buildId) => buildId === building.building_id
        )
      ) {
        this.selectedBuilding = this.selectedBuilding.filter(
          (build) => build !== building.building_id
        );
      } else {
        this.selectedBuilding.push(building.building_id);
      }

      console.log(this.selectedBuilding);
    },
    submitHandler() {
      this.axios
        .post(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.ASSIGN_BUILDING_TO_STAFF,
          {
            staff_id: this.staffData.staff_id,
            building_ids: this.selectedBuilding,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.selectedBuilding = [];
            this.fetchUnAssignedBuildings();
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
  mounted() {
    this.fetchUnAssignedBuildings();
  },
};
</script>
