<template>
  <div class="col-md-12 col-sm-6 col-12">
    <div class="info-box mt-3" :style="bodyBgColor">
      <span :class="showBgColor"><i :class="showIcon()"></i></span>

      <div class="info-box-content">
        <span class="info-box-text">{{ showMessage }}</span>
      </div>
      <div class="form-check form-switch" style="margin-top: 1rem">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          v-model="changeStatus"
          @change="changeCaretakerStatus()"
          id="flexSwitchCheckDefault"
        />
        <i
          class="fas fa-question-circle fake-link"
          title="Change the Status directly with this switch do not press edit button"
        ></i>
      </div>
    </div>
  </div>
</template>
<style scoped>
.fake-link:hover {
  cursor: pointer;
}
</style>
<script>
export default {
  data() {
    return {
      changeStatus: this.status === 1 ? true : false,
    };
  },
  props: ["status"],
  computed: {
    showMessage() {
      if (this.status) {
        return "This Staff is Currently Active";
      } else {
        return "This Staff is Currently Inactive";
      }
    },
    showBgColor() {
      return this.status
        ? "info-box-icon bg-success"
        : "info-box-icon bg-danger";
    },
    bodyBgColor() {
      return this.status
        ? "background-color:rgb(144, 251, 144);"
        : "background-color:rgb(255, 135, 135);";
    },
  },
  inject: ["staff_id"],
  methods: {
    showIcon() {
      return this.status ? "fas fa-check" : "fas fa-times";
    },
    changeCaretakerStatus() {
      // console.log(this.changeStatus)
      this.axios
        .patch(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.CHANGE_STAFF_STATUS +
            "/" +
            this.staff_id,
          {
            change_status: this.changeStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.$emit('success-status-change');
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
};
</script>
