<template>
  <div class="col-md-9 mx-auto mt-2">
    <base-card cardClass="card card-info">
      <template #card-header>
        <h3 class="card-title">Add New Resource</h3>
      </template>
      <form class="form-horizontal" @submit.prevent="addResourceHandler()">
        <div class="card-body">
          <div class="form-group row">
            <label for="resource name" class="col-sm-2 col-form-label"
              >Resource Name<AsteriskSymbol
            /></label>
            <div class="col-sm-10">
              <input
                type="text"
                :class="resourceNameClass"
                placeholder="Eg: Room 210 | Auditorium"
                v-model="resourceName"
              />
              <ErrorMessage v-if="resourceNameError"
                >Resource Name is Invalid</ErrorMessage
              >
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label"
              >Building Name<AsteriskSymbol
            /></label>
            <div class="col-md">
              <div class="form-group">
                <select
                  :class="buildingNameClass"
                  style="width: 100%"
                  v-model="buildingName"
                >
                  <!-- <option selected="selected">A</option> -->
                  <option value="">Select</option>
                  <option
                    v-for="building in buildingNames"
                    :key="building.building_name"
                    :value="building.building_id"
                  >
                    {{ building.building_name }}
                  </option>
                </select>
                <ErrorMessage v-if="buildingNameError"
                  >Please Select a valid Building</ErrorMessage
                >
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label"
              >Resource Type<AsteriskSymbol
            /></label>
            <div class="col-md">
              <div class="form-group">
                <select
                  :class="resourceTypeClass"
                  style="width: 100%"
                  v-model="resourceType"
                >
                  <option selected="selected" value="sole">Sole</option>
                  <option value="distributed">Distributed</option>
                </select>
                <ErrorMessage v-if="resourceTypeError"
                  >Please Select a valid Resource Type</ErrorMessage
                >
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label"
              >Category<AsteriskSymbol
            /></label>
            <div class="col-md">
              <div class="form-group">
                <select
                  :class="resourceCategoryClass"
                  style="width: 100%"
                  v-model="resourceCategory"
                >
                  <option
                    v-for="category in categories"
                    :key="category.category_id"
                    :value="category.category_id"
                  >
                    {{ category.category_name }}
                  </option>
                </select>
                <ErrorMessage v-if="resourceCategoryError"
                  >Please Select a valid Resource Category</ErrorMessage
                >
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-info">Add New Resource</button>
          <button type="reset" class="btn btn-default float-right">
            Clear
          </button>
        </div>
      </form>
    </base-card>
  </div>
</template>
<script>
import axios from "axios";
import BaseCard from "../UI/BaseCard.vue";
import AsteriskSymbol from "../misc/AsteriskSymbol.vue";
import ErrorMessage from "../UI/ErrorMessage.vue";
export default {
  computed: {
    resourceNameClass() {
      return this.resourceNameError
        ? "form-control is-invalid"
        : "form-control";
    },
    buildingNameClass() {
      return this.buildingNameError
        ? "form-control is-invalid"
        : "form-control";
    },
    resourceCategoryClass() {
      return this.resourceCategoryError
        ? "form-control is-invalid"
        : "form-control";
    },
    resourceTypeClass() {
      return this.resourceTypeError
        ? "form-control is-invalid"
        : "form-control";
    },
  },
  data() {
    return {
      resourceNameError: null,
      buildingNameError: null,
      resourceTypeError: null,
      resourceCategoryError: null,
      buildingNames: {},
      resourceName: "",
      buildingName: "",
      resourceType: "sole",
      resourceCategory: "STAFF_ROOM",
      categories: [],
    };
  },
  components: {
    BaseCard,
    AsteriskSymbol,
    ErrorMessage,
  },
  async mounted() {
    this.fetchCategories();
    const token = localStorage.getItem("apiToken");

    const res = await axios.get(
      this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.GET_ALL_BUILDINGS,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status === 200) {
      this.buildingNames = res.data.data;
    } else if (res.status === 204) {
      this.$Methods.toastAlert("No Resources found Please Add Some");
    }
  },
  methods: {
    async addResourceHandler() {
      const token = localStorage.getItem("apiToken");
      axios
        .post(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.RESOURCE_API,
          {
            building_ref_id: this.buildingName,
            resource_name: this.resourceName,
            resource_category: this.resourceCategory,
            resource_type: this.resourceType,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.$router.push("/getAllResources");
          } else {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 422) {
            if (err.response.data.errors.building_ref_id) {
              this.buildingNameError = err.response.data.errors.building_ref_id;
            } else {
              this.buildingNameError = null;
            }
            if (err.response.data.errors.resource_name) {
              this.resourceNameError = err.response.data.errors.resource_name;
            } else {
              this.resourceNameError = null;
            }
            if (err.response.data.errors.resource_category) {
              this.resourceCategoryError =
                err.response.data.errors.resource_category;
            } else {
              this.resourceCategoryError = null;
            }
            if (err.response.data.errors.resource_type) {
              this.resourceTypeError = err.response.data.errors.resource_type;
            } else {
              this.resourceTypeError = null;
            }
          }
        });
    },
    fetchCategories() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESOURCE_CATEGORY
        )
        .then((res) => {
          if (res.status === 200) {
            this.categories = res.data.data;
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
  },
};
</script>
