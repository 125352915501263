// import VueSweetalert2 from "vue-sweetalert2";
import axios from "axios";
import Constants from "./Constants.js";
import Swal from "sweetalert2";
const logging = (msg) => {
  console.log("thhe message is " + msg);
};
const alert = () => {
  Swal.fire({
    text: "error found",
    icon: "warning",
  });
};

const warningAlert = (message) => {
  Swal.fire({
    text: message,
    icon: "warning",
  });
};

const errorAlert = (message) => {
  Swal.fire({
    text: message,
    icon: "error",
  });
};

const successAlert = (message) => {
  Swal.fire({
    text: message,
    icon: "success",
  });
};

const toastSuccess = (message) => {
  var toastMixin = Swal.mixin({
    toast: true,
    icon: "success",
    title: message,
    // animation: true,
    timerProgressBar: true,
    position: "top-right",
    showConfirmButton: false,
    timer: 5000,
  });
  toastMixin.fire();
};

const toastWarning = (message) => {
  var toastMixin = Swal.mixin({
    toast: true,
    icon: "warning",
    // background: '#ffff66',
    title: message,
    // animation: true,
    timerProgressBar: true,
    position: "top-right",
    showConfirmButton: false,
    timer: 5000,
  });
  toastMixin.fire();
};

const toastError = (message) => {
  var toastMixin = Swal.mixin({
    toast: true,
    icon: "error",
    // background: '#ff0000',
    title: message,
    // animation: true,
    timerProgressBar: true,
    position: "top-right",
    showConfirmButton: false,
    timer: 5000,
  });
  toastMixin.fire();
};

const deleteConfirmDialog = (message)=>{
  const confirmBox = Swal.mixin({
    title: 'Are you sure?',
    text: message,
    icon: 'warning',
    allowEnterKey:false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  })
  return confirmBox;
}


const checkUserValidity = async () => {
  let flag = false;
  if (localStorage.getItem("apiToken")) {
    let token = localStorage.getItem("apiToken");
    await axios
      .get(Constants.BaseUrl + Constants.ApiEndpoints.GET_TOKEN_STATUS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // console.log("logged in");
          flag = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return flag;
};

function createDebounce() {
  let timeout = null;
  return function (fnc, delayMs) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs || 500);
  };
}

const getFromCheckUserValidity = async () => {
  let res = await checkUserValidity();
  return res;
};

const getUserData = async () => {
  let token = localStorage.getItem("apiToken");
  let userData = "";
  await axios
    .get(Constants.BaseUrl + Constants.ApiEndpoints.GET_TOKEN_STATUS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        userData = response;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return userData;
};
const renameKey = (obj, oldKey, newKey, oldKey1, newKey1)=> {
  obj[newKey] = obj[oldKey];
  obj[newKey1] = obj[oldKey1];
  delete obj[oldKey];
  delete obj[oldKey1];
}
export default {
  logging,
  alert,
  checkUserValidity,
  warningAlert,
  successAlert,
  errorAlert,
  toastSuccess,
  toastWarning,
  toastError,
  getUserData,
  getFromCheckUserValidity,
  deleteConfirmDialog,
  createDebounce,
  renameKey
};
