<template>
  <table-view>
    <template #card-header>
      <div class="row">
        <div class="col">Cleaning Completed</div>
        <div class="col-2 ml-auto">
          <download-excel
            :data="json_data"
            :fields="json_fields"
            class="btn btn-success"
            type="xlsx"
            :name="excelFileName"
          >
            Download Excel
            <i class="fa fa-download"></i>
          </download-excel>
        </div>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Name</th>
      <th>AC Unique Name</th>
      <th>Date</th>
      <th>Status</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr
        v-for="(clean, index) in cleaningData"
        :key="clean.ac_clean_id"
      >
        <td>{{ index + 1 }}</td>
        <td>{{ clean.staff_firstname + " " + clean.staff_lastname }}</td>
        <td>{{ clean.unique_alias }}</td>
        <td>{{ clean.created_at }}</td>
        <td><span :class="textColor(clean.status)">{{ pascalCase(clean.status) }}</span></td>
        <td>
          <router-link :to="'/showImages/' + clean.ac_clean_id"
            >View</router-link
          >
        </td>
      </tr>
    </template>
  </table-view>
</template>
<script>
import TableView from "../UI/TableView.vue";
import _ from "lodash";
export default {
  data() {
    return {
      cleaningData: {},
      json_data: [],
      json_fields: {
        "AC Name": "unique_alias",
        "First Name": "staff_firstname",
        "Last Name": "staff_lastname",
        "Cleaned At": "created_at",
        Status: "status",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  components: { TableView },
  computed: {
    excelFileName() {
      return (
        "completed_cleaning_between_" + this.startDate + "_" + this.endDate
      );
    },
  },
  props: ["startDate", "endDate"],
  watch: {
    startDate() {
      this.fetchCleaningData();
    },
    endDate() {
      this.fetchCleaningData();
    },
  },
  methods: {
    pascalCase(msg){
      return _.upperFirst(_.camelCase(msg));
    },
    textColor(status) {
      if (status === "APPROVED" || status === "APPROVED & CHECKED") {
        return "text-success";
      } else if (status === "REJECTED") {
        return "text-danger";
      } else {
        return "text-warning";
      }
    },
    fetchCleaningData() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.FETCH_CLEANING_DATA +
            "/" +
            this.startDate +
            "/" +
            this.endDate,
          {
            headers: {
              Authorization: `Bearer ${this.$token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.cleaningData = res.data.data;
            this.convertJsonToArray();
            // console.log(this.cleaningData)
          } else if (res.status === 204) {
            this.$Methods.toastError(
              "No Cleaning Record Found for selected Date"
            );
          }
        })
        .catch((err) => {
          this.$Methods.toastError(err.response.data.message);
        });
    },
    convertJsonToArray() {
      for (let index in this.cleaningData) {
        this.json_data.push(this.cleaningData[index]);
      }
    },
  },
  mounted() {
    this.fetchCleaningData();
  },
};
</script>
