<template>
  <table-view :isLoading="isLoading">
    <template #card-header>
      <h3 class="card-title">All Resources</h3>
      <div class="col-2 ml-auto">
        <router-link
          to="/addNewResource"
          class="btn btn-block bg-gradient-success"
        >
          Add New
        </router-link>
      </div>
    </template>
    <template #table-header>
      <th>Sr No.</th>
      <th>Resource Name</th>
      <th>Building</th>
      <th>Type</th>
      <th>Category</th>
      <th>Created By</th>
      <th>Actions</th>
    </template>
    <template #default>
      <tr
        v-for="(resource, index) in resourcesData"
        :key="resource.resource_id"
      >
        <td>{{ index + 1 }}</td>
        <td>{{ resource.resource_name }}</td>
        <td>{{ resource.building_name }}</td>
        <td>{{ resource.resource_type }}</td>
        <td>{{ resource.category_name }}</td>
        <td>{{ resource.name }}</td>
        <td>
          <span @click="editHandler(resource.resource_id)" class="show-pointer">
            <i
              class="fas fa-edit p-2"
              style="color: rgb(255, 153, 0); font-size: 22px"
            ></i>
          </span>
          <span
            @click="deleteHandler(resource.resource_id)"
            class="show-pointer"
            ><i
              class="fas fa-trash p-2"
              style="color: red; font-size: 22px"
            ></i>
          </span>
        </td>
      </tr>
    </template>
  </table-view>
</template>
<script>
import TableView from "../UI/TableView.vue";
import axios from "axios";

export default {
  data() {
    return {
      isLoading: false,
      resourcesData: {},
    };
  },
  components: {
    TableView,
  },
  methods: {
    editHandler(id) {
      this.$router.push(`/editResource/${id}`);
    },
    deleteHandler(id) {
      axios
        .delete(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.RESOURCE_API +
            `/${id}`
        )
        .then((res) => {
          if (res.status === 200) {
            this.$Methods.toastSuccess(res.data.message);
            this.getAllResources();
          } else if (res.status === 500) {
            this.$Methods.toastError(res.data.message);
          }
        })
        .catch((err) => {
          if (err.response.status === 500) {
            this.$Methods.toastError(err.response.data.message);
          } else if (err.response.status === 409) {
            this.$Methods.errorAlert(
              "This data is Connected to some other data please remove that and try again!!"
            );
          }
        });
    },
    getAllResources() {
      this.isLoading=true;
      axios
        .get(
          this.$Constants.BaseUrl + this.$Constants.ApiEndpoints.RESOURCE_API
        )
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.resourcesData = res.data.data;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },

  async mounted() {
    this.getAllResources();
  },
};
</script>
