import { createApp } from "vue";
import App from "./App.vue";
window.$ = window.jQuery = require("jquery");

//importing dependecies
import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import Multiselect from "@vueform/multiselect";
import VueDatePicker from "@vuepic/vue-datepicker";
import JsonExcel from "vue-json-excel3";

//importing utils
import Methods from "./utils/Methods.js";
import Constants from "./utils/Constants";

//importing components
import TheLogin from "./components/auth/TheLogin.vue";
import GuestDashboard from "./components/home/GuestDashboard.vue";
import NotFound from "./components/shared/NotFound.vue";
import AuthenticatedDashboard from "./components/home/AuthenticatedDashboard.vue";
import ViewAllBuildings from "./components/buildings/ViewAllBuildings.vue";
import AddNewBuilding from "./components/buildings/AddNewBuilding.vue";
import EditBuilding from "./components/buildings/EditBuildingForm.vue";
import GetAllResources from "./components/resources/GetAllResources.vue";
import addNewResource from "./components/resources/AddNewResource.vue";
import EditResource from "./components/resources/EditResource.vue";
import ViewAllSubResource from "./components/sub_resources/ViewAllSubResource.vue";
import AddNewSubResource from "./components/sub_resources/AddNewSubResource.vue";
import EditSubResource from "./components/sub_resources/EditSubResource.vue";
import ViewAllAirConditioner from "./components/air_conditioner/ViewAllAirConditioner.vue";
import AddNewAirConditioner from "./components/air_conditioner/AddNewAirConditioner.vue";
import EditAirConditioner from "./components/air_conditioner/EditAirConditioner.vue";
import ViewAllStaff from "./components/staff/ViewAllStaff.vue";
import AddNewStaff from "./components/staff/AddNewStaff.vue";
import EditStaff from "./components/staff/EditStaff.vue";
import BaseButton from "./components/UI/BaseButton.vue";
import BaseCard from "./components/UI/BaseCard.vue";
import CardLayout from "./components/UI/CardLayout.vue";
import ExampleForm from "./components/ExampleForm.vue";
import ShowAllDepartment from "./components/department/ShowAllDepartment.vue";
import AddNewDepartment from "./components/department/AddNewDepartment.vue";
import EditDepartment from "./components/department/EditDepartment.vue";
import AssignUnAssignAC from "./components/staff/AssignUnAssignAC.vue";
import DateWiseReport from "./components/reports/DateWiseReport.vue";
import StaffWiseReport from "./components/reports/StaffWiseReport.vue";
import ShowImages from "./components/reports/ShowImages.vue";
import ManageProfile from "./components/admin/ManageProfile.vue";
import AddNewAdmin from "./components/admin/AddNewAdmin.vue";
import ActivateAccount from "./components/admin/ActivateAccount.vue";
import ForgotPassword from "./components/auth/ForgotPassword.vue";
import EditPassword from "./components/auth/EditPassword.vue";
import AssignUnAssignSupervisor from "./components/staff/AssignUnAssignSupervisor.vue";
import ViewAllCompany from "./components/ac_company/ViewAllCompany.vue";
import ViewAllCategories from "./components/categories/ViewAllCategories.vue";
import EditCompany from "./components/ac_company/EditCompany.vue";
import EditCategory from "./components/categories/EditCategory.vue";
import LoadingScreen from "./components/shared/LoadingScreen.vue";

//importing icons and fonts
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faGooglePlus } from "@fortawesome/free-brands-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import "@vueform/multiselect/themes/default.css";
import "bootstrap";
import "@vuepic/vue-datepicker/dist/main.css";
import "@splidejs/vue-splide/css/sea-green";

// importing admin lte js files
import "../node_modules/admin-lte/dist/js/adminlte.min.js";
import "../node_modules/admin-lte/plugins/select2/js/select2.full.min.js";
import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js";
import "../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js";
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js";
import "../node_modules/admin-lte/plugins/jquery-knob/jquery.knob.min.js";
import "../node_modules/admin-lte/plugins/sparklines/sparkline.js";
import "../node_modules/admin-lte/plugins/jquery/jquery.min.js";
import "../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js";
import "../node_modules/admin-lte/plugins/inputmask/jquery.inputmask.min.js";
import "../node_modules/admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js";
import "../node_modules/admin-lte/plugins/bs-stepper/js/bs-stepper.min.js";
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.js";

//importing admin lte styles
import "../node_modules/admin-lte/dist/css/adminlte.min.css";
import "../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.css";
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css";
import "../node_modules/admin-lte/plugins/jqvmap/jqvmap.min.css";
import "../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css";
import "../node_modules/admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css";
import "../node_modules/admin-lte/plugins/bs-stepper/css/bs-stepper.min.css";
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.css";
const app = createApp(App);
const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  routes: [
    { path: "/", name: "default", component: GuestDashboard },
    { path: "/login", name: "login", component: TheLogin },
    { path: "/home", name: "home", component: AuthenticatedDashboard },
    {
      path: "/viewAllBuildings",
      name: "viewAllBuildings",
      component: ViewAllBuildings,
    },
    {
      path: "/addNewBuilding",
      name: "addNewBuilding",
      component: AddNewBuilding,
    },
    {
      path: "/editBuilding/:id",
      name: "editBuilding",
      component: EditBuilding,
      props: true,
    },

    {
      path: "/getAllResources",
      name: "getAllResources",
      component: GetAllResources,
    },
    {
      path: "/addNewResource",
      name: "addNewResource",
      component: addNewResource,
    },
    {
      path: "/editResource/:id",
      name: "editResource",
      component: EditResource,
      props: true,
    },

    {
      path: "/viewAllSubResource",
      name: "viewAllSubResource",
      component: ViewAllSubResource,
    },

    {
      path: "/addNewSubResource",
      name: "addNewSubResource",
      component: AddNewSubResource,
    },
    {
      path: "/editSubResource/:id",
      name: "editSubResource",
      component: EditSubResource,
      props: true,
    },
    {
      path: "/viewAllAirConditioner",
      name: "viewAllAirConditioner",
      component: ViewAllAirConditioner,
    },
    {
      path: "/addNewAirConditioner",
      name: "addNewAirConditioner",
      component: AddNewAirConditioner,
    },
    {
      path: "/editAirConditioner/:id",
      name: "editAirConditioner",
      component: EditAirConditioner,
      props: true,
    },
    {
      path: "/viewAllStaff",
      name: "viewAllStaff",
      component: ViewAllStaff,
    },
    {
      path: "/addNewStaff",
      name: "addNewStaff",
      component: AddNewStaff,
    },
    {
      path: "/editStaff/:id",
      name: "editStaff",
      component: EditStaff,
      props: true,
    },

    {
      path: "/showAllDept",
      name: "showAllDept",
      component: ShowAllDepartment,
    },
    {
      path: "/addNewDept",
      name: "addNewDept",
      component: AddNewDepartment,
    },
    {
      path: "/editDept/:id",
      name: "editDept",
      component: EditDepartment,
      props: true,
    },
    {
      path: "/assignUnAssignAC",
      name: "assignUnAssignAC",
      component: AssignUnAssignAC,
    },
    {
      path: "/dateWiseReport",
      name: "dateWiseReport",
      component: DateWiseReport,
    },
    {
      path: "/caretakerWiseReport",
      name: "caretakerWiseReport",
      component: StaffWiseReport,
    },
    {
      path: "/showImages/:cleanId",
      name: "showImages",
      component: ShowImages,
      props: true,
    },
    {
      path: "/manageProfile",
      name: "manageProfile",
      component: ManageProfile,
    },
    {
      path: "/addNewAdmin",
      name: "addNewAdmin",
      component: AddNewAdmin,
    },
    {
      path: "/assignUnAssignACSupervisor",
      name: "assignUnAssignACSupervisor",
      component: AssignUnAssignSupervisor,
    },
    {
      path: "/activateAccount/:token",
      name: "activateAccount",
      component: ActivateAccount,
      props: true,
    },
    {
      path: "/forgotPassword",
      name: "forgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/editPassword/:token",
      name: "editPassword",
      component: EditPassword,
      props: true,
    },
    {
      path: "/viewAllCompany",
      name: "viewAllCompany",
      component: ViewAllCompany,
    },
    {
      path: "/editAcCompany/:companyId/:companyName",
      name: "editAcCompany",
      component: EditCompany,
      props: true,
    },
    {
      path: "/viewAllCategories",
      name: "viewAllCategories",
      component: ViewAllCategories,
    },
    {
      path: "/editCategory/:categoryId/:category",
      name: "editCategory",
      component: EditCategory,
      props: true,
    },
    {
      path: "/misc",
      name: "misc",
      component: ExampleForm,
    },

    { path: "/:notFound(.*)", name: "notFound", component: NotFound },
  ],
});
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("apiToken");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    } else {
      console.log("No token found");
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

// const authorizedApiClient = axios.create({
//   baseURL: Constants.BaseUrl,

//   // headers: {
//   //   Accept: 'application/json'
//   // }
// });
// authorizedApiClient.interceptors.request.use((config) => {
//   if (localStorage.getItem("apiToken")){ // or get it from localStorage
//     config.headers["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
//   }
//   return config
// })
// app.config.globalProperties.$userLoggedIn = true;

// axios.interceptors.response.use(null, (error) => {
//   if (error.response && error.response.status === 401) {
//     localStorage.clear();
//     app.config.globalProperties.$userLoggedIn = false;
//     window.location.href = "/login";
//     // Methods.toastWarning("Please Verify yourself by loggin in");
//   }
// });
app.config.globalProperties.$userLoggedIn = await Methods.checkUserValidity();

router.beforeEach(async (to, from, next) => {
  if (
    !app.config.globalProperties.$userLoggedIn &&
    localStorage.getItem("apiToken") !== null
  ) {
    app.config.globalProperties.$token = localStorage.getItem("apiToken");

    app.config.globalProperties.$userLoggedIn =
      await Methods.checkUserValidity();
  }
  if (
    app.config.globalProperties.$userLoggedIn &
    ((to.name === "home") |
      (to.name === "default") |
      (to.name === "viewAllBuildings") |
      (to.name === "addNewBuilding") |
      (to.name === "editBuilding") |
      (to.name === "notFound") |
      (to.name === "getAllResources") |
      (to.name === "addNewResource") |
      (to.name === "editResource") |
      (to.name === "viewAllSubResource") |
      (to.name === "addNewSubResource") |
      (to.name === "editSubResource") |
      (to.name === "viewAllAirConditioner") |
      (to.name === "addNewAirConditioner") |
      (to.name === "editAirConditioner") |
      (to.name === "viewAllStaff") |
      (to.name === "addNewStaff") |
      (to.name === "editStaff") |
      (to.name === "viewAllSupervisors") |
      (to.name === "showAllDept") |
      (to.name === "addNewDept") |
      (to.name === "editDept") |
      (to.name === "assignUnAssignAC") |
      (to.name === "dateWiseReport") |
      (to.name === "caretakerWiseReport") |
      (to.name === "showImages") |
      (to.name === "manageProfile") |
      (to.name === "addNewAdmin") |
      (to.name === "assignUnAssignACSupervisor") |
      (to.name === "viewAllCompany") |
      (to.name === "editAcCompany") |
      (to.name === "viewAllCategories") |
      (to.name === "editCategory") |
      (to.name === "misc"))
  ) {
    next();
  } else if (
    !app.config.globalProperties.$userLoggedIn &
    ((to.name === "default") |
      (to.name === "login") |
      (to.name === "notFound") |
      (to.name === "activateAccount") |
      (to.name === "forgotPassword") |
      (to.name === "editPassword"))
  ) {
    next();
  } else {
    window.location.href = "/";
  }
});

library.add(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus);
dom.watch();
app.config.globalProperties.$Constants = Constants;
app.config.globalProperties.$Methods = Methods;
app.use(router);
app.use(VueAxios, axios);
app.component("font-awesome-icon", FontAwesomeIcon);
if (localStorage.getItem("apiToken")) {
  app.config.globalProperties.$token = localStorage.getItem("apiToken");
  // console.log(app.config.globalProperties.$token)
}
app.component("base-button", BaseButton);
app.component("base-card", BaseCard);
app.component("card-layout", CardLayout);
app.component("multi-select", Multiselect);
app.component("vue-date-picker", VueDatePicker);
app.component("downloadExcel", JsonExcel);
app.component("loading-screen", LoadingScreen);
app.mount("#app");
