<template>
  <base-card>
    <div class="col-md-4 mt-3">
      <multi-select
        v-model="selectedBuildings"
        :options="allBuildings"
        :searchable="true"
        mode="tags"
        placeholder="Select Institute"
      ></multi-select>
    </div>
  </base-card>
</template>
<script>
export default {
  data() {
    return {
      allBuildings: [],
      selectedBuildings: [],
    };
  },
  watch: {
    selectedBuildings() {
      console.log("this changed......");
      this.$emit("building-on-change",this.selectedBuildings);
    },
  },
  methods: {
    convertKeys() {
      this.allBuildings.forEach((data) => {
        this.$Methods.renameKey(data, "alias", "label", "alias", "value");
      });
    },
    fetchBuildings() {
      this.axios
        .get(
          this.$Constants.BaseUrl +
            this.$Constants.ApiEndpoints.GET_ALL_BUILDINGS
        )
        .then((res) => {
          if (res.status === 200) {
            this.allBuildings = res.data.data;
            this.convertKeys();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Methods.toastError(err?.response?.data?.message);
        });
    },
  },
  mounted() {
    this.fetchBuildings();
  },
};
</script>
